/**
 * @fileoverview gRPC-Web generated client stub for chat
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: chat.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as chat_pb from './chat_pb'; // proto import: "chat.proto"


export class ChatClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorReply = new grpcWeb.MethodDescriptor(
    '/chat.Chat/Reply',
    grpcWeb.MethodType.UNARY,
    chat_pb.ChatMessage,
    chat_pb.ChatReply,
    (request: chat_pb.ChatMessage) => {
      return request.serializeBinary();
    },
    chat_pb.ChatReply.deserializeBinary
  );

  reply(
    request: chat_pb.ChatMessage,
    metadata?: grpcWeb.Metadata | null): Promise<chat_pb.ChatReply>;

  reply(
    request: chat_pb.ChatMessage,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.ChatReply) => void): grpcWeb.ClientReadableStream<chat_pb.ChatReply>;

  reply(
    request: chat_pb.ChatMessage,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.ChatReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/chat.Chat/Reply',
        request,
        metadata || {},
        this.methodDescriptorReply,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/chat.Chat/Reply',
    request,
    metadata || {},
    this.methodDescriptorReply);
  }

}

