/**
 * @fileoverview gRPC-Web generated client stub for quiz
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: quiz.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as quiz_pb from './quiz_pb'; // proto import: "quiz.proto"


export class QuizClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorAddQuestion = new grpcWeb.MethodDescriptor(
    '/quiz.Quiz/AddQuestion',
    grpcWeb.MethodType.UNARY,
    quiz_pb.Question,
    quiz_pb.Question,
    (request: quiz_pb.Question) => {
      return request.serializeBinary();
    },
    quiz_pb.Question.deserializeBinary
  );

  addQuestion(
    request: quiz_pb.Question,
    metadata?: grpcWeb.Metadata | null): Promise<quiz_pb.Question>;

  addQuestion(
    request: quiz_pb.Question,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: quiz_pb.Question) => void): grpcWeb.ClientReadableStream<quiz_pb.Question>;

  addQuestion(
    request: quiz_pb.Question,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: quiz_pb.Question) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/quiz.Quiz/AddQuestion',
        request,
        metadata || {},
        this.methodDescriptorAddQuestion,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/quiz.Quiz/AddQuestion',
    request,
    metadata || {},
    this.methodDescriptorAddQuestion);
  }

  methodDescriptorAddSubject = new grpcWeb.MethodDescriptor(
    '/quiz.Quiz/AddSubject',
    grpcWeb.MethodType.UNARY,
    quiz_pb.Subject,
    quiz_pb.Subject,
    (request: quiz_pb.Subject) => {
      return request.serializeBinary();
    },
    quiz_pb.Subject.deserializeBinary
  );

  addSubject(
    request: quiz_pb.Subject,
    metadata?: grpcWeb.Metadata | null): Promise<quiz_pb.Subject>;

  addSubject(
    request: quiz_pb.Subject,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: quiz_pb.Subject) => void): grpcWeb.ClientReadableStream<quiz_pb.Subject>;

  addSubject(
    request: quiz_pb.Subject,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: quiz_pb.Subject) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/quiz.Quiz/AddSubject',
        request,
        metadata || {},
        this.methodDescriptorAddSubject,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/quiz.Quiz/AddSubject',
    request,
    metadata || {},
    this.methodDescriptorAddSubject);
  }

  methodDescriptorGetSubjects = new grpcWeb.MethodDescriptor(
    '/quiz.Quiz/GetSubjects',
    grpcWeb.MethodType.UNARY,
    quiz_pb.TenantRequest,
    quiz_pb.SubjectList,
    (request: quiz_pb.TenantRequest) => {
      return request.serializeBinary();
    },
    quiz_pb.SubjectList.deserializeBinary
  );

  getSubjects(
    request: quiz_pb.TenantRequest,
    metadata?: grpcWeb.Metadata | null): Promise<quiz_pb.SubjectList>;

  getSubjects(
    request: quiz_pb.TenantRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: quiz_pb.SubjectList) => void): grpcWeb.ClientReadableStream<quiz_pb.SubjectList>;

  getSubjects(
    request: quiz_pb.TenantRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: quiz_pb.SubjectList) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/quiz.Quiz/GetSubjects',
        request,
        metadata || {},
        this.methodDescriptorGetSubjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/quiz.Quiz/GetSubjects',
    request,
    metadata || {},
    this.methodDescriptorGetSubjects);
  }

  methodDescriptorGetContest = new grpcWeb.MethodDescriptor(
    '/quiz.Quiz/GetContest',
    grpcWeb.MethodType.UNARY,
    quiz_pb.ContestRequest,
    quiz_pb.Contest,
    (request: quiz_pb.ContestRequest) => {
      return request.serializeBinary();
    },
    quiz_pb.Contest.deserializeBinary
  );

  getContest(
    request: quiz_pb.ContestRequest,
    metadata?: grpcWeb.Metadata | null): Promise<quiz_pb.Contest>;

  getContest(
    request: quiz_pb.ContestRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: quiz_pb.Contest) => void): grpcWeb.ClientReadableStream<quiz_pb.Contest>;

  getContest(
    request: quiz_pb.ContestRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: quiz_pb.Contest) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/quiz.Quiz/GetContest',
        request,
        metadata || {},
        this.methodDescriptorGetContest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/quiz.Quiz/GetContest',
    request,
    metadata || {},
    this.methodDescriptorGetContest);
  }

  methodDescriptorSubmitQuizAnswer = new grpcWeb.MethodDescriptor(
    '/quiz.Quiz/SubmitQuizAnswer',
    grpcWeb.MethodType.UNARY,
    quiz_pb.CandidateResponse,
    quiz_pb.SubmissionResponse,
    (request: quiz_pb.CandidateResponse) => {
      return request.serializeBinary();
    },
    quiz_pb.SubmissionResponse.deserializeBinary
  );

  submitQuizAnswer(
    request: quiz_pb.CandidateResponse,
    metadata?: grpcWeb.Metadata | null): Promise<quiz_pb.SubmissionResponse>;

  submitQuizAnswer(
    request: quiz_pb.CandidateResponse,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: quiz_pb.SubmissionResponse) => void): grpcWeb.ClientReadableStream<quiz_pb.SubmissionResponse>;

  submitQuizAnswer(
    request: quiz_pb.CandidateResponse,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: quiz_pb.SubmissionResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/quiz.Quiz/SubmitQuizAnswer',
        request,
        metadata || {},
        this.methodDescriptorSubmitQuizAnswer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/quiz.Quiz/SubmitQuizAnswer',
    request,
    metadata || {},
    this.methodDescriptorSubmitQuizAnswer);
  }

}

