/**
 * @fileoverview gRPC-Web generated client stub for quiz
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.26.1
// source: stats.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as stats_pb from './stats_pb'; // proto import: "stats.proto"


export class StatClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetStats = new grpcWeb.MethodDescriptor(
    '/quiz.Stat/GetStats',
    grpcWeb.MethodType.UNARY,
    stats_pb.StatRequest,
    stats_pb.Stats,
    (request: stats_pb.StatRequest) => {
      return request.serializeBinary();
    },
    stats_pb.Stats.deserializeBinary
  );

  getStats(
    request: stats_pb.StatRequest,
    metadata?: grpcWeb.Metadata | null): Promise<stats_pb.Stats>;

  getStats(
    request: stats_pb.StatRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: stats_pb.Stats) => void): grpcWeb.ClientReadableStream<stats_pb.Stats>;

  getStats(
    request: stats_pb.StatRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: stats_pb.Stats) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/quiz.Stat/GetStats',
        request,
        metadata || {},
        this.methodDescriptorGetStats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/quiz.Stat/GetStats',
    request,
    metadata || {},
    this.methodDescriptorGetStats);
  }

}

